@keyframes scroll {
  to {
    transform: translateX(calc(-50% - 0.5rem));
  }
}

.scroller {
  max-width: 550px;
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroll-content {
  width: max-content;
  padding-block: 0.5rem;
  display: flex;
  gap: 1rem;
  animation: scroll 10s linear infinite;
  flex-wrap: nowrap;
}

.scroll-content:hover {
  animation-play-state: paused;
}
